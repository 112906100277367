
import { defineComponent } from "vue";
import Messenger from "@/messaging";
import { mapMutations, mapState } from "vuex";
import PublicPage from "./PublicPage.vue";
import PrivatePage from "./PrivatePage.vue";
export default defineComponent({
  components: {
    PublicPage,
    PrivatePage,
  },
  props: ["privateIDURL", "publicIDURL"],
  data() {
    return {
      label: "",
    };
  },
  mounted() {
    if (Number(this.publicIDURL)) {
      this.setPublicId(Number(this.publicIDURL));
      setTimeout(
        () => Messenger.subscribeStatus(Number(this.publicIDURL)),
        1000
      );

      if (this.privateIDURL) {
        this.setPrivateId(Number(this.privateIDURL));
      }
    } else {
      setTimeout(() => Messenger.createStatus(), 1000);
    }
  },
  methods: {
    ...mapMutations(["setPrivateId", "setPublicId"]),
  },
  computed: {
    ...mapState(["privateId"]),
  },
});
