import { createStore } from "vuex";
import { Checkpoint, Task } from "@/messaging/messages";

export interface Checkpoints {
  [details: string]: Checkpoint;
}

const checkpoints: Checkpoints = {};

export interface Tasks {
  [details: string]: Task;
}

const tasks: Tasks = {};

export default createStore({
  state: {
    label: "",
    privateId: -1,
    publicId: -1,
    checkpoints,
    tasks,
    total: -1,
    etd: "",
    online: false,
  },
  mutations: {
    setPrivateId(state, private_id: number) {
      state.privateId = private_id;
    },
    setPublicId(state, public_id: number) {
      state.publicId = public_id;
    },
    setLabel(state, label: string) {
      state.label = label;
    },
    addCheckpoint(state, checkpoint: Checkpoint) {
      const key = checkpoint.time + "";
      state.checkpoints[key] = checkpoint;
    },
    setTotal(state, total: number) {
      state.total = total;
    },
  },
  actions: {},
  getters: {
    getToDoTaskNumber(state) {
      return Object.values(state.tasks).filter(
        (task: Task) => task.status == "ToDo"
      ).length;
    },
    getDoneTaskNumber(state) {
      return Object.values(state.tasks).filter(
        (task: Task) => task.status == "Done"
      ).length;
    },
    estimatedTaskDelivery(state) {
      if (!(state.total && Object.keys(state.checkpoints).length > 1)) {
        return;
      }
      const sortedKeys = Object.keys(state.checkpoints).sort(
        (a, b) => Number(a) - Number(b)
      );

      const first = state.checkpoints[sortedKeys[0]];
      const last = state.checkpoints[sortedKeys[sortedKeys.length - 1]];

      const date1 = first.time;
      const date2 = last.time;

      const diffTime = date2 - date1;

      const diffNumber = last.value - first.value;
      const timeLeft = (state.total - last.value) * (diffTime / diffNumber);

      return new Date(date2 * 1000 + timeLeft * 1000).toLocaleString();
    },
    maxValueCheckpoint(state) {
      let max = 0;
      for (const element of Object.keys(state.checkpoints)) {
        max = Math.max(state.checkpoints[element].value, max);
      }
      return max;
    },
  },
  modules: {},
});
