import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_list = _resolveComponent("list")!

  return (Object.keys(_ctx.checkpoints).length > 0)
    ? (_openBlock(), _createBlock(_component_list, { key: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkpoints, (checkpoint, i) => {
              return (_openBlock(), _createElementBlock("li", {
                key: i,
                class: "flex justify-between"
              }, [
                _createTextVNode(_toDisplayString(new Date(checkpoint.time * 1000).toLocaleString()) + " " + _toDisplayString(checkpoint.value) + " ", 1),
                (_ctx.edit)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      onClick: ($event: any) => (_ctx.remove(checkpoint))
                    }, {
                      default: _withCtx(() => _cache[0] || (_cache[0] = [
                        _createTextVNode("remove")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}