<template>
  <button
    class="
      rounded
      ring-2 ring-red-500 ring-opacity-50
      p-2
      ml-2
      bg-red-500
      inline
    "
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: [""],
};
</script>
