
import CreateCheckpoint from "@/components/CreateCheckpoint.vue";
import ListCheckpoints from "@/components/ListCheckpoints.vue";
import ListTasks from "@/components/ListTasks.vue";
import CreateTask from "@/components/CreateTask.vue";
import Share from "@/components/Share.vue";
import Total from "@/components/Total.vue";
import Label from "@/components/Label.vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";
import Title from "@/styled-components/Title.vue";
export default defineComponent({
  components: {
    Share,
    Label,
    Total,
    CreateTask,
    ListTasks,
    CreateCheckpoint,
    ListCheckpoints,
    Title,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["privateId", "publicId"]),
  },
});
