
import { defineComponent } from "vue";
import Messenger from "@/messaging";
import { TaskStatus } from "@/messaging/messages";
import Card from "@/styled-components/Card.vue";
import Button from "@/styled-components/Button.vue";
export default defineComponent({
  components: { Card, Button },
  data() {
    return {
      label: "",
    };
  },
  methods: {
    create() {
      Messenger.setTask({
        label: this.label,
        status: "ToDo" as TaskStatus,
      });
      this.label = "";
    },
  },
});
