
import ConnectionState from "@/components/ConnectionState.vue";
import EstimatedTimeDelivery from "@/components/EstimatedTimeDelivery.vue";
import ListCheckpoints from "@/components/ListCheckpoints.vue";
import ListTasks from "@/components/ListTasks.vue";
import Status from "@/components/Status.vue";
import Total from "@/components/Total.vue";
import Label from "@/components/Label.vue";
import Title from "@/styled-components/Title.vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";
export default defineComponent({
  components: {
    Label,
    Status,
    ListTasks,
    ListCheckpoints,
    EstimatedTimeDelivery,
    ConnectionState,
    Title,
    Total,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["privateId", "publicId"]),
  },
});
