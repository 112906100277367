
import { defineComponent } from "vue";
import Messenger from "@/messaging";
import Card from "@/styled-components/Card.vue";
import Button from "@/styled-components/Button.vue";
export default defineComponent({
  components: { Card, Button },
  data() {
    return {
      amountDone: 0,
      time:
        this.pad(new Date().getHours()) +
        ":" +
        this.pad(new Date().getMinutes()) +
        ":" +
        this.pad(new Date().getSeconds()),
      date: new Date().toISOString().substr(0, 10),
    };
  },
  methods: {
    create() {
      const date = new Date(this.date);

      date.setHours(Number(this.time.split(":")[0]));
      date.setMinutes(Number(this.time.split(":")[1]));
      date.setSeconds(Number(this.time.split(":")[2]));
      Messenger.setCheckpoint({
        value: Number(this.amountDone),
        time: date.getTime() / 1000,
      });
    },
    setNow() {
      this.time =
        this.pad(new Date().getHours()) +
        ":" +
        this.pad(new Date().getMinutes()) +
        ":" +
        this.pad(new Date().getSeconds());
      this.date = new Date().toISOString().substr(0, 10);
    },
    pad(i: number) {
      if (i < 10) {
        return 0 + "" + i;
      } else {
        return i;
      }
    },
  },
});
