<template>
  <card v-if="edit || total">
    <div v-if="edit">
      <div>Checkpoints total</div>
      <input type="number" placeholder="0000" v-model="writableValue" />
      <Button @click="send">SEND</Button>
    </div>
    <div v-else>
      <div>Checkpoints total {{ total }}</div>
    </div>
  </card>
</template>

<script>
import Messenger from "@/messaging";
import { mapState } from "vuex";
import Card from "@/styled-components/Card.vue";
import Button from "@/styled-components/Button.vue";
export default {
  components: { Card, Button },
  name: "total",

  data() {
    return {
      writableValue: 0,
    };
  },

  props: {
    edit: { type: Boolean, required: true },
  },

  methods: {
    send() {
      Messenger.setTotal(Number(this.writableValue));
      this.writableValue = 0;
    },
  },
  computed: {
    ...mapState(["total"]),
  },
  watch: {
    total: function (val) {
      this.writableValue = val;
    },
  },
};
</script>

<style></style>
