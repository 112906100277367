<template>
  <div>
    <div class="text-lg">
      <h4><slot></slot></h4>
    </div>
  </div>
</template>

<script>
export default {
  props: [""],
};
</script>
