
import { defineComponent } from "vue";
import { mapState } from "vuex";
export default defineComponent({
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState(["online"]),
  },
});
