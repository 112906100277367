<template>
  <div>
    <card v-if="estimatedTaskDelivery">
      Estimated Time of Delivery (checkpoints):
      <h3>{{ estimatedTaskDelivery }}</h3>
    </card>
  </div>
</template>

<script>
import Card from "@/styled-components/Card.vue";
import { mapGetters, mapState } from "vuex";
export default {
  components: { Card },
  name: "status",
  props: {
    etd: String,
    lastCheckpoint: Number,
  },
  computed: {
    ...mapState(["total"]),
    ...mapGetters(["estimatedTaskDelivery", "maxValueCheckpoint"]),
  },
};
</script>

<style></style>
