import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_connection_state = _resolveComponent("connection-state")!
  const _component_Label = _resolveComponent("Label")!
  const _component_Title = _resolveComponent("Title")!
  const _component_status = _resolveComponent("status")!
  const _component_estimated_time_delivery = _resolveComponent("estimated-time-delivery")!
  const _component_total = _resolveComponent("total")!
  const _component_list_checkpoints = _resolveComponent("list-checkpoints")!
  const _component_list_tasks = _resolveComponent("list-tasks")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", null, [
        _createVNode(_component_connection_state)
      ]),
      _createVNode(_component_Title, null, {
        default: _withCtx(() => [
          _createVNode(_component_Label)
        ]),
        _: 1
      }),
      _createElementVNode("div", null, [
        _createVNode(_component_status)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_estimated_time_delivery),
        _createVNode(_component_total, { edit: false }),
        _createElementVNode("div", null, [
          _createVNode(_component_list_checkpoints, { edit: false })
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_list_tasks, { edit: false })
        ])
      ])
    ])
  ]))
}