import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomePage from "../views/HomePage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Test",
    component: HomePage,
  },

  {
    path: "/:publicIDURL",
    name: "TestPublic",
    component: HomePage,
    props: true,
  },

  {
    path: "/:publicIDURL/:privateIDURL",
    name: "TestPrivate",
    component: HomePage,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
