import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = {
  key: 0,
  class: "text-red-700"
}
const _hoisted_3 = {
  key: 1,
  class: "bg-green-700 text-white rounded"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.online)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "/!\\ OFFLINE /!\\"))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, "ONLINE"))
  ]))
}