
import Messenger from "@/messaging";
import { Task } from "@/messaging/messages";
import Button from "@/styled-components/Button.vue";
import List from "@/styled-components/List.vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";
export default defineComponent({
  components: { List, Button },
  props: {
    edit: { type: Boolean, required: true },
  },
  data() {
    return {};
  },
  methods: {
    remove(task: Task) {
      Messenger.removeTask(task);
    },
    changeState(task: Task) {
      Messenger.setTask({
        label: task.label,
        status: task.status === "Done" ? "ToDo" : "Done",
      });
    },
  },
  computed: {
    ...mapState(["tasks"]),
  },
});
