import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, { class: "flex justify-between" }, {
    default: _withCtx(() => [
      _cache[5] || (_cache[5] = _createElementVNode("div", null, "Checkpoint", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "number",
        placeholder: "12345",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.amountDone) = $event))
      }, null, 512), [
        [_vModelText, _ctx.amountDone]
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "date",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.date) = $event))
      }, null, 512), [
        [_vModelText, _ctx.date]
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "time",
        min: "00:00",
        max: "23:59",
        step: "2",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.time) = $event))
      }, null, 512), [
        [_vModelText, _ctx.time]
      ]),
      _createVNode(_component_Button, { onClick: _ctx.setNow }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("NOW")
        ])),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_Button, { onClick: _ctx.create }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("SEND")
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}