
import { defineComponent } from "vue";
import { mapState } from "vuex";
import Messenger from "@/messaging";
import Card from "@/styled-components/Card.vue";
import Button from "@/styled-components/Button.vue";
export default defineComponent({
  components: { Card, Button },
  data() {
    return {
      writableLabel: "",
    };
  },
  props: {
    edit: { type: Boolean, required: true },
  },
  methods: {
    sendLabel() {
      Messenger.sendMessage(this.writableLabel);
    },
  },
  computed: {
    ...mapState(["label"]),
  },
  watch: {
    label: function (val) {
      this.writableLabel = val;
    },
  },
});
