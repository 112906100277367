
import Messenger from "@/messaging";
import { Checkpoint } from "@/messaging/messages";
import Button from "@/styled-components/Button.vue";
import List from "@/styled-components/List.vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";
export default defineComponent({
  components: { List, Button },
  props: {
    edit: { type: Boolean, required: true },
  },
  data() {
    return {};
  },
  methods: {
    remove(checkpoint: Checkpoint) {
      Messenger.removeCheckpoint(checkpoint);
    },
  },
  computed: {
    ...mapState(["checkpoints"]),
  },
});
