
import Card from "@/styled-components/Card.vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  components: { Card },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
    };
  },
  computed: {
    ...mapState(["privateId", "publicId"]),
  },
});
