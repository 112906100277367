<template>
  <div>
    <div v-if="maxValueCheckpoint > 0 && maxValueCheckpoint <= total">
      <card>
        <h3>
          Checkpoints: {{ maxValueCheckpoint }}/{{ total }}
          <progress
            id="file"
            max="100"
            :value="(maxValueCheckpoint / total) * 100"
          >
            {{ (maxValueCheckpoint / total) * 100 }}%
          </progress>
        </h3>
      </card>
    </div>

    <div v-if="Object.keys(tasks).length > 0">
      <card>
        <h3>
          Tasks: {{ getDoneTaskNumber }}/{{ Object.keys(tasks).length }}
          <progress
            id="file"
            max="100"
            :value="(getDoneTaskNumber / Object.keys(tasks).length) * 100"
          >
            {{ (getDoneTaskNumber / Object.keys(tasks).length) * 100 }}%
          </progress>
        </h3>
      </card>
    </div>
  </div>
</template>

<script>
import Card from "@/styled-components/Card.vue";
import { mapGetters, mapState } from "vuex";
export default {
  components: { Card },
  name: "status",
  props: {
    etd: String,
    lastCheckpoint: Number,
  },
  computed: {
    ...mapState(["total", "tasks"]),
    ...mapGetters([
      "estimatedTaskDelivery",
      "maxValueCheckpoint",
      "getDoneTaskNumber",
      "getToDoTaskNumber",
    ]),
  },
};
</script>

<style></style>
