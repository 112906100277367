import {
  RemoveTask,
  ApplicationCommand,
  isReadStatus,
  isReadID,
  UpdateStatusLabel,
  CreateStatus,
  RequestReadStatus,
  SetCheckpoint,
  Checkpoint,
  SetTotal,
  RemoveCheckpoint,
  Task,
  SetTask,
} from "./messages";
import store from "../store";
import router from "../router";

class Messaging {
  webSocket: WebSocket | undefined;
  queue: ApplicationCommand[] = [];

  constructor() {
    this.manageConnection();
  }

  manageConnection() {
    if (this.webSocket && this.webSocket?.readyState === 0) {
      return;
    }

    if (!store.state.online) {
      try {
        console.log("Connecting...");
        this.webSocket = new WebSocket(process.env.VUE_APP_BACKEND_URL);
        this.assignListeners();
      } catch {
        store.state.online = false;

        return;
      }
    }
  }

  assignListeners() {
    if (this.webSocket) {
      this.webSocket.onopen = this.onOpen;
      this.webSocket.onclose = () => this.onClose();
      this.webSocket.onmessage = this.onMessage;
    }
  }

  webSocketSender(command: ApplicationCommand) {
    if (store.state.online && this.webSocket) {
      this.webSocket.send(JSON.stringify(command));
    }
  }

  sendMessage(label: string) {
    this.webSocketSender(<UpdateStatusLabel>{
      type: "UpdateStatusLabel",
      label,
      private_id: store.state.privateId,
      public_id: store.state.publicId,
    });
  }

  createStatus() {
    this.webSocketSender(<CreateStatus>{
      type: "CreateStatus",
    });
  }

  setCheckpoint(checkpoint: Checkpoint) {
    this.webSocketSender(<SetCheckpoint>{
      type: "SetCheckpoint",
      private_id: store.state.privateId,
      public_id: store.state.publicId,
      checkpoint,
    });
  }

  removeCheckpoint(checkpoint: Checkpoint) {
    this.webSocketSender(<RemoveCheckpoint>{
      type: "RemoveCheckpoint",
      private_id: store.state.privateId,
      public_id: store.state.publicId,
      checkpoint,
    });
  }

  setTotal(total: number) {
    this.webSocketSender(<SetTotal>{
      type: "SetTotal",
      private_id: store.state.privateId,
      public_id: store.state.publicId,
      total,
    });
  }

  setTask(task: Task) {
    this.webSocketSender(<SetTask>{
      type: "SetTask",
      private_id: store.state.privateId,
      public_id: store.state.publicId,
      task,
    });
  }

  removeTask(task: Task) {
    this.webSocketSender(<RemoveTask>{
      type: "RemoveTask",
      private_id: store.state.privateId,
      public_id: store.state.publicId,
      task,
    });
  }

  subscribeStatus(publicId: number) {
    this.webSocketSender(<RequestReadStatus>{
      type: "RequestReadStatus",
      public_id: publicId,
    });
  }

  onOpen() {
    store.state.online = true;
  }

  onClose(this: Messaging,) {
    store.state.online = false;
    this.manageConnection();
  }

  onMessage(ev: MessageEvent) {
    const msg = JSON.parse(ev.data);
    if (isReadStatus(msg)) {
      store.state.label = msg.label;
      store.state.publicId = msg.public_id;
      store.state.checkpoints = msg.checkpoints;
      store.state.total = msg.total;
      store.state.tasks = msg.tasks;
    } else if (isReadID(msg)) {
      store.state.privateId = msg.private_id;
      store.state.publicId = msg.public_id;
      console.log(router.currentRoute);
      router.replace("/" + msg.public_id + "/" + msg.private_id);
    }
  }
}

const Messenger = new Messaging();
export default Messenger;
