import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col md:flex-row justify-evenly" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_private_page = _resolveComponent("private-page")!
  const _component_public_page = _resolveComponent("public-page")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "bg-blue-400 p-4 rounded-xl mb-4 text-2xl" }, "E.T.D. Tasks", -1)),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.privateId > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_private_page)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createVNode(_component_public_page)
      ])
    ])
  ]))
}