<template>
  <div>
    <div
      class="
        box-border
        border-2 border-gray-600
        rounded
        p-2
        m-4
        shadow
        bg-gradient-to-r
        from-gray-400
        to-gray-500
      "
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
};
</script>
