import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, null, {
    default: _withCtx(() => [
      (_ctx.edit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[2] || (_cache[2] = _createElementVNode("label", {
              for: "label-input",
              class: "mr-4"
            }, "Status label", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.writableLabel) = $event)),
              placeholder: "text",
              id: "label-input",
              class: "rounded"
            }, null, 512), [
              [_vModelText, _ctx.writableLabel]
            ]),
            _createVNode(_component_Button, { onClick: _ctx.sendLabel }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("SEND")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.label)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.label), 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, " No label. Please stay here for more, we will send you updates. "))
          ]))
    ]),
    _: 1
  }))
}