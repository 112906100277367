<template>
  <div>
    <div class="rounded-xl p-2 m-4 bg-white">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: [""],
};
</script>
