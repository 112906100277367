// APPLICATION COMMANDS
export type CreateStatus = {
  type: "CreateStatus";
};

export type UpdateStatusLabel = {
  type: "UpdateStatusLabel";
  private_id: number;
  public_id: number;
  label: string;
};

export type Status = {
  type: "Status";
  label: string;
  checkpoints: [];
  public_id: number;
  private_id: number;
  total: number;
};

export type RequestReadStatus = {
  type: "RequestReadStatus";
  public_id: number;
};

export type Checkpoint = {
  value: number;
  time: number;
};

export type TaskStatus = "Done" | "ToDo";

export type Task = {
  label: string;
  status: TaskStatus;
};

export type SetCheckpoint = {
  type: "SetCheckpoint";
  private_id: number;
  public_id: number;
  checkpoint: Checkpoint;
};

export type RemoveCheckpoint = {
  type: "RemoveCheckpoint";
  private_id: number;
  public_id: number;
  checkpoint: Checkpoint;
};

export type SetTotal = {
  type: "SetTotal";
  private_id: number;
  public_id: number;
  total: number;
};
export type RemoveStatus = {
  type: "RemoveStatus";
  public_id: number;
};

export type SetTask = {
  type: "SetTask";
  private_id: number;
  public_id: number;
  task: Task;
};

export type RemoveTask = {
  type: "RemoveTask";
  private_id: number;
  public_id: number;
  task: Task;
};

export type ApplicationCommand =
  | CreateStatus
  | UpdateStatusLabel
  | SetTotal
  | SetCheckpoint
  | RemoveStatus
  | RemoveCheckpoint
  | RequestReadStatus
  | SetTask
  | RemoveTask;

// APPLICATION QUERIES
export type ApplicationQuery = ReadStatus | ReadID;

interface Checkpoints {
  [details: string]: Checkpoint;
}

interface Tasks {
  [details: string]: Task;
}

export type ReadStatus = {
  type: "ReadStatus";
  label: string;
  checkpoints: Checkpoints;
  public_id: number;
  total: number;
  tasks: Tasks;
};

export type ReadID = {
  type: "ReadID";
  private_id: number;
  public_id: number;
};

export function isReadStatus(status: ApplicationQuery): status is ReadStatus {
  return status.type === "ReadStatus";
}

export function isReadID(status: ApplicationQuery): status is ReadID {
  return status.type === "ReadID";
}
