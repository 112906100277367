import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id", "checked", "disabled", "onInput"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_list = _resolveComponent("list")!

  return (Object.keys(_ctx.tasks).length > 0)
    ? (_openBlock(), _createBlock(_component_list, { key: 0 }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createElementVNode("h5", null, "Tasks")
            ])
          ], -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasks, (task, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "flex justify-start"
            }, [
              _createElementVNode("input", {
                type: "checkbox",
                id: 'id-' + i,
                checked: task.status == 'Done',
                disabled: !_ctx.edit,
                onInput: ($event: any) => (_ctx.changeState(task))
              }, null, 40, _hoisted_1),
              _createElementVNode("label", {
                for: 'id-' + i,
                class: "ml-3"
              }, _toDisplayString(task.label), 9, _hoisted_2),
              (_ctx.edit)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    class: "ml-3",
                    onClick: ($event: any) => (_ctx.remove(task))
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("remove")
                    ])),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}